import React from "react"

import WebpImage from "../WebpImage"
import { glassesIncludesList, sunglassesIncludesList } from "./mockedData"

import "./style.scss"

const ProductIncluded = ({ type, image, includes, title }) => {
  return (
    <div className="product-included" data-type={type}>
      <div className="grid-section">
        <div className="text-block">
          <h3 className="text h3 heading">What’s Included?</h3>
          <div className="includes">
            {type === 'glasses' &&
              (includes || glassesIncludesList).map((text, index) => (
                <div className="prop-item" key={index}>
                  <span className="circle-icon text h10">{index + 1}</span>
                  <span className="text h7 neue description">{text}</span>
                </div>
              ))
            }
            {type === 'sunglasses' &&
              (includes || sunglassesIncludesList).map((text, index) => (
                <div className="prop-item" key={index}>
                  <span className="circle-icon text h10">{index + 1}</span>
                  <span className="text h7 neue description">{text}</span>
                </div>
              ))
            }

          </div>
        </div>
        <div className="image-block">
          <WebpImage
            fileName={image}
            alt={`Hubble ${title} ${type} with included tri-fold carrying case and microfiber cloth `}
          />
        </div>
      </div>
    </div>
  )
}

export default ProductIncluded
