import React, { useContext, useEffect } from "react"

import "./style.scss"
import ProductSlider from "../ProductSlider"
import ProductDetails from "../ProductDetails"
import NewButton from "../NewButton"
import { colorTags } from "../../services/data"
import CartContext from "../../context/cartContext"
import Banner from "../Banner"
import useIsLifemart from '../Lifemart/hooks/useIsLifemart'

const GlassesInfo = ({ item, onSelect, variant, setVariant }) => {
  const { type, price, productGender, size, hubbleFrameVariants } = item
  const isKidsSize = size.toLowerCase() === "kids"
  const isGlasses = type === 'glasses'
  const isLifemart = useIsLifemart()

  const filterSlides = variant => {
    const isMaleSlide = productGender.toLowerCase() === "male"
    const isFemaleSlide = productGender.toLowerCase() === "female"
    const isUnisexSlide = productGender.toLowerCase() === "unisex"
    let slides = []

    // need to do gender logic here
    if (isGlasses) {
      slides = [
        variant[`wideImage1`],
        variant[`wideImage2`],
        variant[`wideImage3`],
        variant["productImage2"],
      ]
    } else {
      slides = [
        variant["sunglassesProductImage2"],
        variant[`sunglassesWideImage1`],
        variant[`sunglassesWideImage2`],
        variant[`sunglassesWideImage3`],
      ]
    }

    if (size.toLowerCase() === "kids") {
      slides = [
        variant[`wideImage1`],
        variant[`wideImage2`],
        variant[`wideImage3`],
      ]
    }

    if ((isFemaleSlide || isUnisexSlide) && !isKidsSize) {
      slides.push(
        item[isGlasses ? "femaleHeaderImage2" : "sunglassesFemaleHeaderImage2"]
      )
    }

    if ((isMaleSlide || isUnisexSlide) && !isKidsSize) {
      slides.push(
        item[isGlasses ? "maleHeaderImage2" : "sunglassesMaleHeaderImage2"]
      )
    }

    return slides
  }

  useEffect(() => {
    // Preload images when the component mounts
    hubbleFrameVariants.map((variant) => {
      filterSlides(variant).forEach((image) => {
        const img = new Image()
        img.src = image
      })
    })
  }, [item])

  return (
    <>
    {isLifemart && <Banner
      bannerClass={`promo-banner affiliate-banner pdp`}
      bannerText={`Get <b>25% off all frames</b> in partnership with LifeMart`}
      bannerBG={colorTags[type]}
    />}
      {/* <Banner
        bannerClass={`${isGlasses ? 'orange' : 'green'} promo-banner pdp`}
        bannerText={
          `Get 40% off all eyeglasses and sunglasses with promo code GIFT40.
          <strong>Now through December 4.</strong>`
        }
      /> */}
      <div className="glasses-info-container" data-type={type}>
        <div className="grid-section">
          <div className="mobile-button-container">
            <NewButton onClick={onSelect} color={colorTags[type]} label="Select Frames" stickyScroll={true} />
          </div>
          <ProductSlider slides={filterSlides(variant)} type={type} isKidsImage={isKidsSize}  product={item} currentVariant={variant} />
          <div className="glasses-info-wrapper">
            <ProductDetails
              currentVariant={variant}
              setVariant={setVariant}
              item={{ ...item, price }}
              onClick={onSelect}
              type={type}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default GlassesInfo
